import React, { useState } from "react";
import { GrAdd } from "react-icons/gr";
import { motion, AnimatePresence } from "framer-motion";

const Modal = ({ isOpen, onClose, data }) => {
  const key = "fdgjdfjfkhkgfk";
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? "" : "hidden"}`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0  flex items-center justify-center">
        <AnimatePresence>
          <motion.div
            className="fixed bottom-0 max-w-md w-full  bg-gray-300 p-6 rounded-t-lg "
            key={key}
            initial={{ y: "100%" }}
            animate={{ y: isOpen ? 0 : "100%" }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
          >
            <div className="flex flex-col justify-center">
              <h2 className=" w-full  text-center text-xl font-semibold">
                Who is this loging in?
              </h2>
              <div className="flex items-center mt-4  overflow-scroll">
                {data != null &&
                  data.map((profile, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full h-full flex flex-col items-center justify-center mt-4 mr-4 cursor-pointer"
                        onClick={() => {
                          onClose(profile);
                        }}
                      >
                        <div className="bg-white rounded-full border border-primary w-24 h-24 p-4">
                          {profile.Gender != null ? (
                            <img
                              className="w-full h-full text-white"
                              src={
                                profile.Gender != null
                                  ? profile.Gender.toLowerCase() == "male"
                                    ? "./assets/boy1.svg"
                                    : "./assets/girl1.svg"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                        <h1 className="text-sm text-black">
                          {profile.firstName}
                        </h1>
                      </div>
                    );
                  })}
                <div
                  onClick={() => {
                    onClose(null);
                  }}
                  className="w-full h-full flex items-center justify-center cursor-pointer"
                >
                  <div className="bg-blue-300 rounded-full border border-dotted border-blue-500 w-24 h-24 p-6">
                    <GrAdd className="w-full h-full text-white" />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Modal;
