import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetSlots, HandleNumber } from "../utils/Api";
import {
  setBasicData,
  setUserData,
  setLoading,
  setOtp,
  setMainUser,
} from "../Redux/Actions/Actions";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast } from "react-toastify";

export default function EnterNumber() {
  const [phone, setPhone] = useState("");
  const [phoneFromInput, setPhoneFromInput] = useState("");
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const [contry, setContry] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  auth.useDeviceLanguage();
 

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(phoneFromInput + "");
    if (phoneNumber != null) {
      setContry("+" + phoneNumber.countryCallingCode);
      setPhone(phoneNumber.nationalNumber);
    }
  }, [phoneFromInput]);

  const onCapthcaVerify=(number)=>{
    Dispatch(setLoading({loading:true,otp:false}))
    const verifier = new RecaptchaVerifier(auth,'recaptcha-container', {'size': 'invisible',});
    verifier.render();
    return signInWithPhoneNumber(auth,number,verifier)
  }

  const onSignUp = async(e) => {
    e.preventDefault();
    if(contry==""&&phone==""){
      toast.error("Please enter your number with country code.")
      return
    }
    if(contry==""){
      toast.error("Country code not selected");
      return
    }
    if(phone==""){
      toast.error("Please input phone number");
      return
    }
    let num = contry+""+phone
    setIsButtonDisabled(true)
    Dispatch(setBasicData({code:contry,number:phone}));
    navigate("/phone-otp")

    //depracted firebase featres
    // await onCapthcaVerify(num).then(res=>{
    //   Dispatch(setOtp(res));
     
     
    // }).catch(e=>{
    //   toast.error("Please re-try.")
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       type: "LOGIN_FAILD",
    //       message:"Error"
    //     })
    //   );
    //   Dispatch(setLoading({loading:false,otp:false}))
    // })
  };

  return (
    <div className="flex justify-center items-center text-thtext bg-gray-100">
      <div className="min-h-screen container items-center ">
        <div className="h-screen flex flex-col justify-start items-center ">
          <div className="max-w-md w-full h-44 bg-phonebg">
            <div
              className="w-full h-full bg-contain bg-no-repeat  bg-center flex items-center justify-center"
              style={{ backgroundImage: "url('./assets/mobilephone.svg')" }}
            >
              <img
                className=" w-16 mt-10 h-full pt-2"
                src="./assets/simcard.svg"
              />
            </div>
          </div>
          <div className="px-8 pt-6 pb-8 max-w-md w-full h-full font-bold">
            <div className="flex flex-col justify-start items-start">
              <h2 className=" text-lg text-center mb-1">
                Enter your phone number
              </h2>
              <p className="text-sm mb-6">
                An OTP will be sent to this for confirmation
              </p>
            </div>
            <form
              className="flex flex-col justify-between h-3/4"
              onSubmit={onSignUp}
            >
              <div className="flex flex-col ">
                <PhoneInput
                defaultCountry="IN"
                  className="pt-2 outline-none bg-transparent border-b border-gray-300 w-full items-center"
                  placeholder="Enter phone number"
                  value={phoneFromInput}
                  onChange={setPhoneFromInput}
                />
                <div id="recaptcha-container" className="mt-2"></div>
              </div>
              
              <button
              disabled ={isButtonDisabled}
                className={`bg-primary h-12 text-white font-bold py-2 px-4 mt-6 rounded-full w-full`}
                type="submit"
              >
                Get OTP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
